import React from 'react'

import { energyBlocksData } from './NewEraEnergyCard.constants'

import * as styles from './styles.module.scss'
import FormBlock from '../mainComponents/FormBlock/FormBlock'

const NewEraEnergyCard = () => {
	return (
		<div className={styles.energyBlockCard}>
			{energyBlocksData.map((block) => {
				switch (block.num) {
					case '00' :
						return (
							<div className={styles.energyBlockCardContainer}>
								<div className={styles.main}>

									<div className={styles.mainHeader}>
										<span>{block.title}</span>
										<span>{block.titleYear}</span>
									</div>
									<div className={styles.mainBlockContainer}>
										<div style={{ backgroundImage: `url(${block.background})` }} className={styles.overlay}>
											<div className={styles.mainTitleBlockContainer}>
												<div className={styles.mainTitleBlock}>
													<h1 className={styles.titleText}>{block.title}</h1>
													<p>{block.subtitle}</p>
												</div>
											</div>
										</div>
										<img className={styles.pageImage} src={block.imageSrc} alt={block.alt} />
									</div>
								</div>
							</div>
						)
					case '01':
						return (
							<div className={styles.energyBlockCardContainer}>
								<div className={styles.pageWrapper}>
									<div className={styles.pageBlockContainer}>
										<div className={styles.titleSide}>
											<sup>{block.num}</sup>
											<h1>{block.title}</h1>
										</div>
										<div className={styles.textBlock}>{block.text}</div>
									</div>
									<img className={styles.pageImage} src={block.imageSrc} alt={block.alt} />
								</div>
							</div>
						)
					case '02':
						return (
							<div className={styles.energyBlockCardContainer}>
								<div className={styles.pageWrapper}>
									<div className={styles.pageBlockContainer}>
										<div className={styles.titleSide}>
											<sup>{block.num}</sup>
											<h1>{block.title}</h1>
										</div>
										<div className={styles.textBlock}>{block.text}</div>
									</div>
									<div className={styles.bigLineBlock}>
										<span className={styles.lineNum}>{block.mainLineNum}</span>
										<div className={styles.line} />
									</div>
									<div className={styles.smallLinesBlock}>
										{block.smallLineNums.map(smallLineNum => {
											return (
												<div className={styles.smallLineBlock}>
													<span className={styles.lineNum}>{smallLineNum}</span>
													<div className={styles.line} />
												</div>
											)
										})}
									</div>
									<img className={styles.pageImage} src={block.imageSrc} alt={block.alt} />
								</div>
							</div>
						)
					case '03':
						return (
							<div className={styles.energyBlockCardContainer}>
								<div className={styles.pageWrapper}>
									<div className={styles.pageBlockContainer}>
										<div className={styles.titleSide}>
											<sup>{block.num}</sup>
											<h1>{block.title}</h1>
										</div>
									</div>
									<p className={styles.colorTitle}>{block.hexTitle}</p>
									<div className={styles.colorBlocks}>
										{block.hex.map(color => {
											return (
												<div className={styles.colorBlock} style={{ backgroundColor: `#${color}` }}>{color}</div>
											)
										})}
									</div>
									<div className={styles.typographyPage}>
										<h1 className={styles.typographyPageTitle}>{block.typography}</h1>
										<div className={styles.typographyPageMain}>
											<div className={styles.typographyBlock}>
												<div className={styles.textTypes}>
													{block.textTypes.map((textType) => {
														return (
															<span className={styles.textType}>{textType}</span>
														)
													})}
												</div>
												<div className={styles.lettersBlock}>{block.letters}</div>
											</div>
											<div className={styles.fontName}>{block.fontName}</div>
										</div>
									</div>
								</div>
							</div>
						)
					case '04':
					case '05':
					case '06':
						return (
							<div className={styles.energyBlockCardContainer}>
								<div className={styles.pageWrapper}>
									<div className={styles.pageBlockContainer}>
										<div className={styles.titleSide}>
											<sup>{block.num}</sup>
											<h1>{block.title}</h1>
										</div>
										<div className={styles.textBlock}>{block.text}</div>
									</div>
									<img className={styles.pageImage} src={block.imageSrc} alt={block.alt} />
								</div>
							</div>
						)
					case '07':
						return (
							<div className={styles.energyBlockCardContainerLastPage}>
								<div style={{ backgroundImage: `url(${block.background})` }} className={styles.pageWrapperLast}>
									<div className={styles.pageBlockContainerLast}>
										<div className={styles.lastBlock}>
											<h1>{block.title}</h1>
											<div className={styles.lastText}>{block.text1}</div>
											<div className={styles.lastText}>{block.text2}</div>
										</div>
									</div>
								</div>
								<div className={styles.footerBlock}>{block.footerText}</div>
							</div>
						)
				}
			})}
			<FormBlock/>
		</div>
	)
}

export default NewEraEnergyCard