import neweraMainImage from '../../assets/images/png/newera-energyBlock/newera00-main.png'
import projectPageImage from '../../assets/images/png/newera-energyBlock/project01-kid-in-mask.png'
import gridPageImage from '../../assets/images/png/newera-energyBlock/grid02-air-analysis.png'
import plantPageImage from '../../assets/images/png/newera-energyBlock/plant04-device-enterprise.png'
import optimizationPageImage from '../../assets/images/png/newera-energyBlock/optimization05-recycling-department.png'
import ratesPageImage from '../../assets/images/png/newera-energyBlock/rates06-pricing.png'
import mainPageEllipse from '../../assets/images/png/newera-energyBlock/main-ellipse.png'
import lastPageEllipse from '../../assets/images/png/newera-energyBlock/last-page-ellipse.png'

export const energyBlocksData = [
  {
    id: 0,
    title: 'Newera Energy',
    titleYear: 2021,
    subtitle: 'Dashboard',
    num: '00',
    imageSrc: neweraMainImage,
    alt: 'air-analysis-img',
    background: mainPageEllipse
  },
  {
    id: 1,
    title: 'Project',
    num: '01',
    imageSrc: projectPageImage,
    alt: 'project_kid-in-mask-img',
    text:
      'Every year, the amount of harmful emissions into the atmosphere is growing exponentially. ' +
      'We have designed a web application that helps to track the amount of emissions in the enterprise, as well as reduce them.',
  },
  {
    id: 2,
    title: 'Grid',
    num: '02',
    imageSrc: gridPageImage,
    alt: 'grid-air-analyze-graphic-img',
    text:
      'The grid was based on an 8pt system.\n' +
      'This system made it easy to design the rest of the pages correctly.',
    mainLineNum: 1440,
    smallLineNums: [48,24,48,56]
  },
  {
    id: 3,
    title: 'Styleguide',
    num: '03',
    hexTitle: 'Color',
    hex:['9CD341','D37641','323232','D3CD41','D34141'],
    typography: 'Typography',
    textTypes: ['H1','H2','H3','Text','Description'],
    letters:
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    fontName: 'Inter',
  },
  {
    id: 4,
    title: 'Plant selection',
    num: '04',
    imageSrc: plantPageImage,
    alt: 'pollution-graphic-img',
    text:
      'On this page the user can monitor the air quality at the enterprise, ' +
      'the amount of emitted substances and optimize them.',
  },
  {
    id: 5,
    title: 'Optimization',
    num: '05',
    imageSrc: optimizationPageImage,
    alt: 'optimization-pollution-graphic-img',
    text:
      'On this page, the user can define the parameters for setting  ' +
      'the required level of the air temperature inside the premises of the enterprise.',
  },
  {
    id: 6,
    title: 'Rates',
    num: '06',
    imageSrc: ratesPageImage,
    alt: 'rates-pollution-graphic-img',
    text:
      'This page provides 3 rates to choose from. The user can choose the tariff he wants.',
  },
  {
    id: 7,
    title: 'NewEra case',
    num: '07',
    background: lastPageEllipse,
    text1:
    `Today we want to share the details of our NewEra project.
    This project belongs to the field of Ecology. 
    Caring for the environment is an integral part of any business. 
    In the 21st century, it is necessary to control and reduce emissions using smart systems. 
    NewEra allows enterprises to minimize emissions of harmful substances into 
    the atmosphere both from one device and from the enterprise as a whole.`,
    text2:
    `Project goal: to create from scratch a web application for calculating 
    the current number of emissions of harmful substances in the atmosphere, 
    as well as the functionality for developing recommendations and a 
    checklist of actions that the enterprise should take to minimize the calculated emissions.`,
    footerText: 'All materials were used for non-commercial purposes and belong to its owners'
  }
  ,
]
