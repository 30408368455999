// extracted by mini-css-extract-plugin
export var energyBlockCard = "styles-module--energyBlockCard--lKtno";
export var pageImage = "styles-module--pageImage--ETxvj";
export var energyBlockCardContainer = "styles-module--energyBlockCardContainer--5px7-";
export var energyBlockCardContainerLastPage = "styles-module--energyBlockCardContainer-last-page--8Xkr7";
export var overlay = "styles-module--overlay--V604C";
export var main = "styles-module--main--P+B1T";
export var mainHeader = "styles-module--main-header--GyPbQ";
export var mainBlockContainer = "styles-module--mainBlockContainer--RoO5h";
export var mainTitleBlockContainer = "styles-module--main-titleBlockContainer--tgevV";
export var pageWrapper = "styles-module--pageWrapper--9eyHC";
export var pageBlockContainer = "styles-module--pageBlockContainer--qOzZs";
export var titleSide = "styles-module--titleSide--MjS04";
export var textBlock = "styles-module--text-block--jBV5q";
export var line = "styles-module--line--xq9Gw";
export var smallLinesBlock = "styles-module--smallLinesBlock--ET+TT";
export var smallLineBlock = "styles-module--smallLineBlock--yX-KH";
export var colorTitle = "styles-module--colorTitle--tXW3R";
export var colorBlocks = "styles-module--colorBlocks--Yuvj3";
export var colorBlock = "styles-module--colorBlock--ow+0h";
export var typographyPage = "styles-module--typographyPage--KrI+q";
export var typographyPageTitle = "styles-module--typographyPage-title--fBORd";
export var typographyPageMain = "styles-module--typographyPage-main--5BoFz";
export var textTypes = "styles-module--textTypes--aH8xt";
export var lettersBlock = "styles-module--lettersBlock--vBCpr";
export var fontName = "styles-module--fontName--fDUkx";
export var pageWrapperLast = "styles-module--pageWrapperLast--KxoXu";
export var pageBlockContainerLast = "styles-module--pageBlockContainerLast--Dwnhb";
export var lastBlock = "styles-module--lastBlock--H7sba";
export var lastText = "styles-module--lastText--HI4Sv";
export var footerBlock = "styles-module--footerBlock--mIicx";